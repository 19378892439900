<template>

  <div id="page-user-list">

    <div class="vx-card p-6" v-show="loaded">

      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <!-- Add Button -->
        <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4 mb-5" @click="addItem" style="position: relative; z-index: 41002;">Add Item</vs-button>

      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>
    </div>

    <!--  Popup Customer  -->
    <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.nama" />
        </div>

        <div class="vx-col md:w-1/1 w-full">
          <vs-input class="w-full mb-3" :label="$i18n.t('Color')" v-model="form.warna" />
        </div>

        <div class="vx-col md:w-1/1 w-full">
          <label for="" class="vs-input--label">{{$i18n.t('Address')}}</label>
          <vs-textarea rows="6" class="w-full mb-3" v-model="form.alamat" />
        </div>
      </div>

      <div class="mt-5">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'


export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      year: '2017',
      form:{},
      mode:'',

      // AgGrid
      popup: false,
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Name'),
          field: 'nama',
          filter: true,
          width: 200,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          cellClass: 'text-center'
        },
        {
          headerName: this.$i18n.t('ChartColor'),
          field: 'warna',
          filter: true,
          width: 350,
          cellRendererFramework: 'CellRendererStatus',
        },
        {
          headerName: 'Action',
          width: 120,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this)
          },
          cellClass: 'text-center'
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },

    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },

    getData(){
      let params = {

      };

      axios.get('/sales/company', {params}).then(resp => this.itemsData = resp.data ).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded();
      })
    },

    // trigger modal add
    addItem(){
      this.popup = true;
      this.mode = 'add';
      this.form = {}
    },

    // edit an item
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        nama: item.nama,
        warna: item.warna,
        alamat: item.alamat
      };
    },

    // store an item
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          nama: this.form.nama,
          warna: this.form.warna,
          alamat: this.form.alamat
        };

        axios.post('/sales/company', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.form = {};
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          nama: this.form.nama,
          warna: this.form.warna,
          alamat: this.form.alamat,
          _method: 'PUT',
        };

        axios.post('/sales/company/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.form = {}
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = '';
    },

    // delete item
    deleteItem (item) {
      axios.delete('/sales/company/' + item.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.loading();
    this.getData();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
