<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    encrypt () {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    }
  },
  methods: {
    editRecord () {
      this.params.editItem(this.params.data);
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You want to delete by customer name #${this.params.data.nama}`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.params.deleteItem(this.params.data);
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }
  }
}
</script>
